import { ConvertParams } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";

export const GetItemListAction = (data) => {
  const path = `inventory-items/get-items${ConvertParams(data)}`;
  const responseType = "GET_ITEM_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetItemStatusList = (data) => {
  const path = `inventory-items/get-inventory-status-list`;
  const responseType = "GET_ITEM_STATUS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetExpectedItemDetailsAction = (id) => {
  const path = `inventory-items/expected-item-details/${id}`;
  const responseType = "GET_EXPECTED_ITEM_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetReceiveItemDropdownDataAction = (data) => {
  const path = `inventory-items/receive-item-dropdown-data`;
  const responseType = "GET_RECEIVE_ITEM_DROPDOWN_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRelatedAmazonListAction = (client_id, asin) => {
  const path = `inventory-items/related-amazon-listings/${client_id}/${asin}`;
  const responseType = "GET_RELATED_AMAZON_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ValidateReceivedItemAction = (id, data) => {
  const path = `inventory-items/${id}/validate-received-item`;
  const responseType = "VALIDATE_RECEIVED_ITEMS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetFindAsinFromBarcodeAction = (client_id, data) => {
  const path = `inventory-items/search-item-asin/${client_id}/${data}`;
  const responseType = "FIND_ASIN_FROM_BARCODE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetFindAdvanceAsinSearchAction = (client_id, data) => {
  const path = `inventory-items/advance-search-items/${client_id}?search_text=${data}`;
  const responseType = "FIND_ADVANCE_ASIN_SEARCH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SaveInventoryItemsWithoutOrder = (data) => {
  const path = `inventory-items/receive-item-without-order`;
  const responseType = "SAVE_INVENTORY_ITEMS_WITHOUT_ORDER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSingleReceivedItemAction = (id) => {
  const path = `inventory-items/${id}`;
  const responseType = "GET_SINGLE_RECEIVED_ITEM";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetInventoryItemsViewStatusList = () => {
  const path = `inventory-item-status/list`;
  const responseType = "GET_INVENTORY_ITEMS_VIEW_STATUS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddInventoryItemPicture = (id, data) => {
  const path = `inventory-items/add-image/${id}`;
  const responseType = "ADD_INVENTORY_ITEM_PICTURE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionInventoryItems = () => async (dispatch) => {
  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Fake action for inventory items.
   *
   * This action is used for debugging purposes. It does not send a request to the server.
   * Instead, it dispatches an action of type "FAKE_ACTION_EXPECTED_ITEMS" to the store.
   *
   * The purpose of this action is to allow the user to see the expected items without
   * having to make a request to the server.
   *
   * @return {Function} The action creator.
   */
  /******  918d8bf4-f034-4847-a294-8487732d8dee  *******/ dispatch({
    type: "FAKE_ACTION_EXPECTED_ITEMS",
  });
};

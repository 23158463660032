import React, { useState, createContext } from "react";
export const GlobalContext = createContext();

const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    profile: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") || {})
      : {},
    "client-list": JSON.parse(localStorage.getItem("client-list")) || [],
  });

  return (
    <GlobalContext.Provider
      value={{
        data: { ...commonGlobalVal },
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;

import React, { useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";

const BarcodeGenerator = ({ value = "123456789" }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, value, {
        format: "CODE128",
        displayValue: true,
        height: 40,
        width: 2,
        lineColor: "#000000",
        fontSize: 12,
      });
    }
  }, [value]);

  return <svg ref={barcodeRef}></svg>;
};

export default BarcodeGenerator;

import {
  Button,
  Image,
  Input,
  Pagination,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
export default function (props) {
  const {
    GetAmazonListing,
    fakeActionAmazonListing,
    GetClientListWithNoPaginationAction,
    fakeActionClient,
  } = props;

  const GetAmzonListingReportRes = useSelector(
    (state) => state.AmazonListing.GetAmzonListingReportResponse || {}
  );
  const GetClientList = useSelector(
    (state) => state.Client.GetClientListResponse || {}
  );

  const [current, setCurrent] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchFilterText, setSearchFilterText] = useState({});
  const searchInput = useRef(null);

  useEffect(() => {
    GetClientListWithNoPaginationAction();
    message.destroy();
    setLoading(true);
    GetAmazonListing({
      ...searchFilterText,
      page: current,
      "per-page": rowsPerPage,
    });
  }, []);

  useEffect(() => {
    if (GetAmzonListingReportRes?.status === true) {
      setList(GetAmzonListingReportRes?.data?.records || []);
      setLoading(false);
      let pagination = GetAmzonListingReportRes?.data?.pagination;
      setTotalRecord(pagination?.totalCount);
      fakeActionAmazonListing();
    } else if (GetAmzonListingReportRes?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionAmazonListing();
    }
  }, [GetAmzonListingReportRes]);

  useEffect(() => {
    if (GetClientList?.status === true) {
      let client_list = GetClientList?.data?.records;
      let client_list_options = client_list.map((item) => {
        return {
          label: item?.client_name_label,
          value: item?.id,
        };
      });
      setClientList(client_list_options);
      fakeActionClient();
    } else if (GetClientList?.status === false) {
      fakeActionClient();
    }
  }, [GetClientList]);

  const onShowSizeChange = (current, pageSize) => {
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAmazonListing({
      ...searchFilterText,
      page: 1,
      "per-page": 10,
    });
  };

  const onPaginationChange = (page, pageSize) => {
    if (current !== page) {
      setCurrent(page);
      setLoading(true);
      GetAmazonListing({
        ...searchFilterText,
        page: page,
        "per-page": pageSize,
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const newFilters = { ...searchFilterText, [dataIndex]: selectedKeys[0] };
    setSearchFilterText(newFilters);
    setSearchedColumn(dataIndex);
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAmazonListing({
      ...newFilters,
      page: 1,
      "per-page": 10,
    });
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, dataIndex, "-selectedKeys, confirm, dataIndex");
    const newObj = Object.keys(searchFilterText).reduce((acc, key) => {
      if (key !== dataIndex) {
        acc[key] = searchFilterText[key];
      }
      return acc;
    }, {});
    clearFilters();
    confirm();
    setSearchFilterText(newObj);
    setSearchedColumn("");
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAmazonListing({
      ...newObj,
      page: 1,
      "per-page": 10,
    });
  };

  // Handle reset (remove specific filter)
  // const handleReset = (clearFilters, confirm) => {
  //   clearFilters();
  //   confirm();
  //   setSearchFilterText({});
  //   setSearchedColumn("");
  //   GetAmazonListing({
  //     page: current,
  //     "per-page": rowsPerPage,
  //   });
  // };
  const getColumnSearchProps = (dataIndex, type = "input", options = []) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {type === "input" && (
          <Input
            ref={searchInput}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className="w-250px"
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        {type === "select" && (
          <Select
            options={options}
            className="w-200px"
            style={{ marginBottom: 8, display: "block" }}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
              handleSearch([e], confirm, dataIndex);
            }}
            placeholder="Select"
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        )}

        <Space>
          {type !== "select" && (
            <>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
              >
                Search
              </Button>
              <Button
                onClick={() =>
                  clearFilters &&
                  handleReset(clearFilters, selectedKeys, confirm, dataIndex)
                }
                size="small"
              >
                Reset
              </Button>
            </>
          )}

          {/* <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
          >
            Reset
          </Button> */}
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#fe5000" : undefined }} />
    ),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
  });

  const columns = [
    // {
    //   title: "Action",
    //   render: (item) => {
    //     return (
    //       <Tooltip title={"Process"}>
    //         <Link
    //           to={`/amazon-listings/details?id=${item?.id}`}
    //           className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
    //         >
    //           <i className="ki-outline ki-watch fs-2 text-info" />
    //         </Link>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      title: "mSKU",
      dataIndex: "seller_sku",
      key: "seller_sku",
      width: 180,
      ...getColumnSearchProps("seller_sku"),
    },
    {
      title: "Name / Title",
      dataIndex: "item_name",
      key: "item_name",
      width: 400,
      ...getColumnSearchProps("item_name"),
      render: (item_name) => {
        return <span>{item_name}</span>;
      },
    },
    {
      title: "Selling Status",
      dataIndex: "item_status",
      key: "item_status",
      width: 150,
      ...getColumnSearchProps("item_status", "select", [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
      ]),
      render: (item_status) => {
        return (
          <Tag color={item_status === "Active" ? "green" : "red"}>
            {item_status}
          </Tag>
        );
      },
    },
    {
      title: "Condition",
      dataIndex: "item_condition",
      key: "item_condition",
      width: 180,
      ...getColumnSearchProps("item_condition", "select", [
        { value: "UsedLikeNew", label: "Used - Like New" },
        { value: "UsedVeryGood", label: "Used - Very Good" },
        { value: "UsedGood", label: "Used - Good" },
        { value: "UsedAcceptable", label: "Used - Acceptable" },
        { value: "CollectibleLikeNew", label: "Collectible - Like New" },
        { value: "CollectibleVeryGood", label: "Collectible - Very Good" },
        { value: "CollectibleGood", label: "Collectible - Good" },
        { value: "CollectibleAcceptable", label: "Collectible - Acceptable" },
        { value: "New", label: "New" },
        { value: "NotAcceptable", label: "Not Acceptable" },
        { value: "Unknown", label: "Unknown" },
      ]),
    },
    {
      title: "Client Id & Name",
      width: 200,
      ...getColumnSearchProps("client_account_id", "select", clientList),
      render: (item) => {
        return (
          <span>
            {item?.client_account_id}
            {" - "}
            {item?.client_account_name}
          </span>
        );
      },
    },

    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ...getColumnSearchProps("asin"),
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "fnSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      ...getColumnSearchProps("fnsku"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
    {
      title: "ISBN - 10",
      dataIndex: "isbn_10",
      key: "isbn_10",
      ...getColumnSearchProps("isbn_10"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
    {
      title: "ISBN - 13",
      dataIndex: "isbn_13",
      key: "isbn_13",
      ...getColumnSearchProps("isbn_13"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
    {
      title: "Price",
      dataIndex: "your_price",
      key: "your_price",
      ...getColumnSearchProps("your_price"),
      render: (value) => (value ? `$${value?.toFixed(2)} ` : "-"),
    },
    {
      title: "Est Fee Total",
      dataIndex: "estimated_fee_total",
      key: "estimated_fee_total",
      ...getColumnSearchProps("estimated_fee_total"),
      render: (value) => (value ? `$${value?.toFixed(2)} ` : "-"),
    },
    {
      title: "FBA Quantity",
      dataIndex: "fba_qty",
      key: "fba_qty",
      ...getColumnSearchProps("fba_qty"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
    {
      title: "Projected LTSF 6 Mo",
      dataIndex: "ltsf6mo",
      key: "ltsf6mo",
      ...getColumnSearchProps("ltsf6mo"),
      render: (value) => (value ? `$${value?.toFixed(2)} ` : "-"),
    },
    {
      title: "Rcmnd Rmvl Qty",
      dataIndex: "recommended_removal_quantity",
      key: "recommended_removal_quantity",
      ...getColumnSearchProps("recommended_removal_quantity"),
      render: (data) => {
        return <span>{data || "-"}</span>;
      },
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row g-5 g-xl-5 mb-5">
              <div className="col-md-12 d-md-flex justify-content-sm-between">
                <div className="d-md-flex justify-content-start">
                  <div className="fs-5 fw-bold text-gray-700 d-flex align-items-center ps-3 pt-5">
                    Showing{" "}
                    {totalRecord === 0
                      ? 0
                      : current === 1
                      ? 1
                      : (current - 1) * rowsPerPage + 1}{" "}
                    to{" "}
                    {totalRecord <= rowsPerPage
                      ? totalRecord
                      : totalRecord < rowsPerPage * current
                      ? totalRecord
                      : rowsPerPage * current}{" "}
                    of {totalRecord} entries
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Amazon Listings
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      {/* <div className="position-relative ms-3">
                        <i
                          className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"
                          style={{ zIndex: 9 }}
                        />
                        <Input
                          placeholder="Search Records"
                          size="large"
                          className="w-300px fs-6 text-dark ps-12 all-search bg-white"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            if (e.target.value.length === 0) {
                              setCurrent(1);
                              setRowsPerPage(10);
                              setLoading(true);
                              GetAmazonListing({
                                search: "",
                                page: 1,
                                "per-page": 10,
                              });
                            }
                          }}
                          value={searchText}
                          onPressEnter={(e) => {
                            setLoading(true);
                            setCurrent(1);
                            setRowsPerPage(10);
                            GetAmazonListing({
                              search: e.target.value,
                              page: 1,
                              "per-page": 10,
                            });
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <>
                        <>
                          <Table
                            columns={columns}
                            dataSource={list}
                            scroll={{ x: "max-content" }}
                            pagination={false}
                            loading={loading}
                          />
                        </>
                      </>
                    </div>
                    {totalRecord > 10 && (
                      <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                        <div />
                        <Pagination
                          showSizeChanger
                          onShowSizeChange={onShowSizeChange}
                          current={current}
                          total={totalRecord}
                          onChange={onPaginationChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { ConvertParams } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";

export const GetClientListAction = (data) => {
  const path = `client-account-list${ConvertParams(data)}`;
  // const path = `client-account-list?search_text=${data?.search || ""}&status=${
  //   data?.status || ""
  // }&page=${data?.page || "1"}&per-page=${data?.perPage || "10"}&id=${
  //   data?.client_id || ""
  // }`;
  const responseType = "GET_CLIENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetClientListWithNoPaginationAction = () => {
  const path = `client-account-dd-list?no_pagination=true`;
  const responseType = "GET_CLIENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// export const GetClientListWithNoPaginationAction = () => {
//     const path = `client-account-list?no_pagination=true&with_prep_center=true`;
//     const responseType = "GET_CLIENT_LIST";
//     return axiosCall("get", path, responseType, null, {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//     });
// };

export const GetClientMasterDataAction = () => {
  const path = `client-account/master-data`;
  const responseType = "GET_CLIENT_MASTER_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddClientAction = (data) => {
  const path = `client-account/create`;
  const responseType = "ADD_CLIENT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateClientAction = (id, data) => {
  const path = `client-account/update/${id}`;
  const responseType = "UPDATE_CLIENT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// For client address
export const AddClientAddressAction = (data) => {
  const path = `client-account/add-address`;
  const responseType = "ADD_CLIENT_ADDRESS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetClientAddressListAction = (id) => {
  const path = `client-account/get-address-list?client_account_id=${id}`;
  const responseType = "GET_CLIENT_ADDRESS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetClientAccountDetailAction = (id) => {
  const path = `client-account/${id}`;
  const responseType = "GET_CLIENT_ACCOUNT_DETAIL";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddPrepCenterAction = (id, data) => {
  const path = `client-account/${id}/assign-prep-center`;
  const responseType = "ADD_PREP_CENTER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdatePrepCenterAction = (id, data) => {
  const path = `client-prep-center-options/${id}/update`;
  const responseType = "UPDATE_PREP_CENTER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetClientAccountDetails = (id) => {
  const path = `/client-account/${id}`;
  const responseType = "GET_CLIENT_ACCOUNT_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionClient = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CLIENT" });
};

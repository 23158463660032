import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AmazonListings from "../../modules/pages/amazon-listings/amazon-listings";
import {
  GetAmazonListing,
  fakeActionAmazonListing,
} from "../../redux/modules/amazon-listing/amazon-listing.action";

import {
  GetClientListWithNoPaginationAction,
  fakeActionClient,
} from "../../redux/modules/client/index.action";

const mapStateToProps = (state) => ({
  GetAmzonListingReportResponse:
    state.AmazonListing.GetAmzonListingReportResponse,

  GetClientListResponse: state.Client.GetClientListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetAmazonListing,
      fakeActionAmazonListing,

      GetClientListWithNoPaginationAction,
      fakeActionClient,
    },
    dispatch
  );

const AmazonListings_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmazonListings);

export default AmazonListings_;

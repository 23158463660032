import { ConvertParams } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";

export const GetAmazonListing = (data) => {
  const path = `amazon-listings/listing-report${ConvertParams(data)}`;
  const responseType = "GET_AMAZON_LISTING_REPORT";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAmazonListing = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AMAZON_LISTING" });
};

import Auth from "../../modules/auth/auth.reducer";
import InventoryOrder from "../../modules/inventory-order/index.reducer";
import Client from "../../modules/client/index.reducer";
import PrepStaff from "../../modules/prep-staff/index.reducer";
import Event from "../../modules/event/index.reducer";
import ScanPackages from "../../modules/scan-deliveries/index.reducer";
import ScanReceivedItems from "../../modules/receive-items/index.reducer";
import preReceivingListings from "../../modules/admin-actions/pre-receiving-listings/index.reducer";
import InventoryItems from "../../modules/inventory-items/index.reducer";
import Location from "../../modules/location/index.reducer";
import ItemLocation from "../../modules/add-item-to-location/index.reducer";
import Source from "../../modules/sources/index.reducer";
import PrepBatch from "../../modules/operation/prep-batch/index.reducer";
import PrepCenter from "../../modules/operation/prep-center/index.reducer";
import CronManager from "../../modules/admin-actions/cron-manager/index.reducer";
import CentralLog from "../../modules/application-logs/central-log/index.reducer";
import ShipPack from "../../modules/operation/prep-batch/ship-pack-reducer";
import Profile from "../../modules/profile/profile.reducer";
import ShellListing from "../../modules/shell-listing/index.reducer";
import GetPictureInfo from "../../modules/inventory-items/get-picture-info/get-picture-info.reducer";
import SendToAmazonFBA from "../../modules/inventory-items/sub-pages/send-to-amazon-fba/index.reducer";
import ExceptionHome from "../../modules/exception-home/exception.reducer";
import AmazonListing from "../../modules/amazon-listing/amazon-listing.reducer";
import SystemDashboard from "../../modules/system-dashboard/index.reducer";
import NewShipmentFlow from "../../modules/operation/prep-batch/index.new-shipment.reducer";
import AsinInfo from "../../modules/asin-info/asin-info.reducer";
import TestingModules from "../../modules/testing-modules/index.reducer";
const reducers = {
  TestingModules,
  Auth,
  InventoryOrder,
  Client,
  PrepStaff,
  Event,
  ScanPackages,
  ScanReceivedItems,
  preReceivingListings,
  InventoryItems,
  Location,
  ItemLocation,
  Source,
  PrepBatch,
  PrepCenter,
  CronManager,
  CentralLog,
  ShipPack,
  Profile,
  ShellListing,
  GetPictureInfo,
  SendToAmazonFBA,
  ExceptionHome,
  AmazonListing,
  SystemDashboard,
  NewShipmentFlow,
  AsinInfo,
};
export default reducers;

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_AMAZON_LISTING_REPORT_SUCCESS":
    case "GET_AMAZON_LISTING_REPORT_ERROR":
      return {
        ...state,
        GetAmzonListingReportResponse: action.updatePayload,
      };

    case "FAKE_ACTION_AMAZON_LISTING":
      return [];
    default:
      return state;
  }
};

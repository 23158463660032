import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AmazonListingDetails from "../../../modules/pages/amazon-listings/lib/amazon-listing-details";
import { fakeActionAmazonListing } from "../../../redux/modules/amazon-listing/amazon-listing.action";

const mapStateToProps = (state) => ({
  // GetAmzonListingReportResponse:
  //   state.AmazonListing.GetAmzonListingReportResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // GetAmazonListing,
      fakeActionAmazonListing,
    },
    dispatch
  );

const AmazonListingDetails_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmazonListingDetails);

export default AmazonListingDetails_;

import {
  Button,
  Image,
  Input,
  Pagination,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../components/loading";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
export default function (props) {
  const {
    GetAsinInfoAction,
    fakeActionAsinInfo,
    GetClientListWithNoPaginationAction,
    fakeActionClient,
  } = props;

  const GetAsinInfoActionRes = useSelector(
    (state) => state.AsinInfo.GetAsinInfoActionResponse || {}
  );
  const GetClientList = useSelector(
    (state) => state.Client.GetClientListResponse || {}
  );

  const [current, setCurrent] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchFilterText, setSearchFilterText] = useState({});
  const searchInput = useRef(null);

  useEffect(() => {
    GetClientListWithNoPaginationAction();
    message.destroy();
    setLoading(true);
    GetAsinInfoAction({
      ...searchFilterText,
      page: current,
      "per-page": rowsPerPage,
    });
  }, []);

  useEffect(() => {
    if (GetAsinInfoActionRes?.status === true) {
      setList(GetAsinInfoActionRes?.data?.records || []);
      setLoading(false);
      let pagination = GetAsinInfoActionRes?.data?.pagination;
      setTotalRecord(pagination?.totalCount);
      fakeActionAsinInfo();
    } else if (GetAsinInfoActionRes?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionAsinInfo();
    }
  }, [GetAsinInfoActionRes]);

  useEffect(() => {
    if (GetClientList?.status === true) {
      let client_list = GetClientList?.data?.records;
      let client_list_options = client_list.map((item) => {
        return {
          label: item?.client_name_label,
          value: item?.id,
        };
      });
      setClientList(client_list_options);
      fakeActionClient();
    } else if (GetClientList?.status === false) {
      fakeActionClient();
    }
  }, [GetClientList]);

  const onShowSizeChange = (current, pageSize) => {
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAsinInfoAction({
      ...searchFilterText,
      page: 1,
      "per-page": 10,
    });
  };

  const onPaginationChange = (page, pageSize) => {
    if (current !== page) {
      setCurrent(page);
      setLoading(true);
      GetAsinInfoAction({
        ...searchFilterText,
        page: page,
        "per-page": pageSize,
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const newFilters = { ...searchFilterText, [dataIndex]: selectedKeys[0] };
    setSearchFilterText(newFilters);
    setSearchedColumn(dataIndex);
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAsinInfoAction({
      ...newFilters,
      page: 1,
      "per-page": 10,
    });
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, dataIndex, "-selectedKeys, confirm, dataIndex");
    const newObj = Object.keys(searchFilterText).reduce((acc, key) => {
      if (key !== dataIndex) {
        acc[key] = searchFilterText[key];
      }
      return acc;
    }, {});
    clearFilters();
    confirm();
    setSearchFilterText(newObj);
    setSearchedColumn("");
    setCurrent(1);
    setRowsPerPage(10);
    setLoading(true);
    GetAsinInfoAction({
      ...newObj,
      page: 1,
      "per-page": 10,
    });
  };

  // Handle reset (remove specific filter)
  // const handleReset = (clearFilters, confirm) => {
  //   clearFilters();
  //   confirm();
  //   setSearchFilterText({});
  //   setSearchedColumn("");
  //   GetAsinInfoAction({
  //     page: current,
  //     "per-page": rowsPerPage,
  //   });
  // };
  const getColumnSearchProps = (dataIndex, type = "input", options = []) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {type === "input" && (
          <Input
            ref={searchInput}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className="w-250px"
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        {type === "select" && (
          <Select
            options={options}
            className="w-200px"
            style={{ marginBottom: 8, display: "block" }}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
              handleSearch([e], confirm, dataIndex);
            }}
            placeholder="Select"
            allowClear
          />
        )}

        <Space>
          {type !== "select" && (
            <>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
              >
                Search
              </Button>
              <Button
                onClick={() =>
                  clearFilters &&
                  handleReset(clearFilters, selectedKeys, confirm, dataIndex)
                }
                size="small"
              >
                Reset
              </Button>
            </>
          )}

          {/* <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
          >
            Reset
          </Button> */}
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#fe5000" : undefined }} />
    ),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
  });

  const columns = [
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ...getColumnSearchProps("asin"),
      width: 100,
      render: (asin) => {
        return <span>{asin}</span>;
      },
    },
    {
      title: "Name / Title",
      dataIndex: "item_name",
      key: "item_name",
      width: 200,
      ...getColumnSearchProps("item_name"),
      render: (item_name) => {
        return (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {item_name}
              </div>
            }
            placement="bottomLeft"
          >
            <div
              className="mb-1 text-one fs-6 popoverActionIconSingleLine"
              style={{ minWidth: "100px" }}
            >
              {item_name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "MarketPlace",
      dataIndex: "marketplace",
      key: "marketplace",
      width: 100,
      ...getColumnSearchProps("marketplace", "select", []),
      render: (marketplace) => {
        return <span>{marketplace}</span>;
      },
    },
    {
      title: "Binding",
      dataIndex: "binding",
      key: "binding",
      width: 120,
      ...getColumnSearchProps("binding"),
      render: (binding) => {
        return <span>{binding}</span>;
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 120,
      ...getColumnSearchProps("brand"),
      render: (brand) => {
        return <span>{brand}</span>;
      },
    },
    {
      title: "ISBN",
      dataIndex: "isbn",
      key: "isbn",
      width: 120,
      ...getColumnSearchProps("isbn"),
      render: (isbn) => {
        return <span>{isbn}</span>;
      },
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      width: 120,
      ...getColumnSearchProps("upc"),
      render: (upc) => {
        return <span>{upc}</span>;
      },
    },
    {
      title: "ISBN-10",
      dataIndex: "isbn10",
      key: "isbn10",
      width: 120,
      ...getColumnSearchProps("isbn10"),
      render: (isbn10) => {
        return <span>{isbn10}</span>;
      },
    },
    {
      title: "ISBN-13",
      dataIndex: "isbn13",
      key: "isbn13",
      width: 120,
      ...getColumnSearchProps("isbn13"),
      render: (isbn13) => {
        return <span>{isbn13}</span>;
      },
    },
    {
      title: "Client Id & Name",
      width: 200,
      ...getColumnSearchProps("client_account_id", "select", clientList),
      render: (item) => {
        return (
          <span>
            {item?.client_account_id}
            {" - "}
            {item?.client_account_name}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row g-5 g-xl-5 mb-5">
              <div className="col-md-12 d-md-flex justify-content-sm-between">
                <div className="d-md-flex justify-content-start">
                  <div className="fs-5 fw-bold text-gray-700 d-flex align-items-center ps-3 pt-5">
                    Showing{" "}
                    {totalRecord === 0
                      ? 0
                      : current === 1
                      ? 1
                      : (current - 1) * rowsPerPage + 1}{" "}
                    to{" "}
                    {totalRecord <= rowsPerPage
                      ? totalRecord
                      : totalRecord < rowsPerPage * current
                      ? totalRecord
                      : rowsPerPage * current}{" "}
                    of {totalRecord} entries
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        ASIN Info
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      {/* <div className="position-relative ms-3">
                        <i
                          className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"
                          style={{ zIndex: 9 }}
                        />
                        <Input
                          placeholder="Search Records"
                          size="large"
                          className="w-300px fs-6 text-dark ps-12 all-search bg-white"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            if (e.target.value.length === 0) {
                              setCurrent(1);
                              setRowsPerPage(10);
                              setLoading(true);
                              GetAsinInfoAction({
                                search: "",
                                page: 1,
                                "per-page": 10,
                              });
                            }
                          }}
                          value={searchText}
                          onPressEnter={(e) => {
                            setLoading(true);
                            setCurrent(1);
                            setRowsPerPage(10);
                            GetAsinInfoAction({
                              search: e.target.value,
                              page: 1,
                              "per-page": 10,
                            });
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <>
                        <>
                          <Table
                            columns={columns}
                            dataSource={list}
                            scroll={{ x: "max-content" }}
                            pagination={false}
                            loading={loading}
                          />
                        </>
                      </>
                    </div>
                    {totalRecord > 10 && (
                      <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                        <div />
                        <Pagination
                          showSizeChanger
                          onShowSizeChange={onShowSizeChange}
                          current={current}
                          total={totalRecord}
                          onChange={onPaginationChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

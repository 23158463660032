import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AsinInfo from "../../modules/pages/asin-info/asin-info";
import {
  GetAsinInfoAction,
  fakeActionAsinInfo,
} from "../../redux/modules/asin-info/asin-info.action";

import {
  GetClientListWithNoPaginationAction,
  fakeActionClient,
} from "../../redux/modules/client/index.action";

const mapStateToProps = (state) => ({
  GetAsinInfoActionResponse: state.AsinInfo.GetAsinInfoActionResponse,

  GetClientListResponse: state.Client.GetClientListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetAsinInfoAction,
      fakeActionAsinInfo,

      GetClientListWithNoPaginationAction,
      fakeActionClient,
    },
    dispatch
  );

const AsinInfo_ = connect(mapStateToProps, mapDispatchToProps)(AsinInfo);

export default AsinInfo_;

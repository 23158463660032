import { ConvertParams } from "../../../config";
import axiosCall from "../../configurations/network-services/axiosCall";

export const GetAsinInfoAction = (data) => {
  const path = `asin-info${ConvertParams(data)}`;
  const responseType = "GET_ASIN_INFO";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAsinInfo = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ASIN_INFO" });
};

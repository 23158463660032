import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { urlDecode } from "../../../../config";
import { Checkbox, Collapse, Input, message } from "antd";
import BarcodeGenerator from "../../../../components/barcode-generator/barcode-generator";

const AmazonListingDetails = () => {
  const location = useLocation();
  const [id, setId] = useState("");

  useEffect(() => {
    const state = urlDecode(location);
    setId(state?.id);
    message.destroy();
    // message.loading("Loading...", 0);
    return () => {};
  }, [location]);
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark">
                        Amazon Listing Details
                      </span>
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body py-2">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div>
                          <label className="fw-bold">Seller Account</label>
                          <Input disabled className="w-200px ms-2" />
                        </div>
                        <div>
                          <button className="btn btn-light-primary btn-sm">
                            Print FBA Label
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-5"></div>
                    <div className="row">
                      <div className="col-12 col-md-7 col-lg-8 col-xl-8 d-flex">
                        <div
                          className="me-5"
                          style={{
                            border: "1px solid #dddddd",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src="/assets/media/test_book.jpg"
                            alt="Book Cover"
                            className="mx-auto rounded"
                            style={{
                              height: "130px",
                              width: "130px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col-12 fw-bold fs-4">
                              Psychology by OpenStax (Official Print Version,
                              hardcover, full color)
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">ISBN-13 : </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">Binding : </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">
                                Sales Rank :
                              </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">ISBN-10 : </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">Edition : </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">
                                Product Group :
                              </label>
                            </div>{" "}
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">UPC : </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">
                                Dimention :{" "}
                              </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <label className="fw-bold me-1">Weight :</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-4 col-xl-4">
                        <div className="row">
                          <div className="col-12">
                            <label className="fw-bold">EZP FBA Label</label>
                          </div>
                          <div className="col-12">
                            <BarcodeGenerator value="X002X2UHUH" />
                          </div>
                          <div className="col-12">
                            <label className="fs-7">
                              Psychology by OpenStax (Official Print Version,
                              hardcover, full color)
                            </label>
                          </div>{" "}
                          <div className="col-12">
                            <label className="fs-6 fw-bold">
                              Used - Very Good
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <label className="fs-2 fw-bold">Amazon Listing</label>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div className="row mt-5">
                      <div className="col-3 mb-4">
                        <label className="fw-bold">mSKU</label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-3 mb-4">
                        <label className="fw-bold">ASIN</label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-2 mb-4">
                        <label className="fw-bold">fnSKU</label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-2 mb-4">
                        <label className="fw-bold">Fulfilled By</label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-2 mb-4">
                        <label className="fw-bold">Status</label>
                        <Input className="w-100" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Listing Created On</label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Listing Last Modified</label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Tax Code</label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-3 mb-4">
                        <Checkbox />
                        <label className="fw-bold">Has Duplicate fnSKU</label>
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div className="col-3 mb-4">
                        <label className="fw-bold">
                          US-FBA Inv - Qty Fulfillable
                        </label>
                        <Input className="w-100" />
                      </div>
                      <div className="col-3 mb-4">
                        <label className="fw-bold">
                          FBA Qty Stranded/UnSellable
                        </label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Next Action Due</label>
                        <Input className="w-100" />
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Condition</label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-9 mb-4">
                        <label className="fw-bold">Marks</label>
                        <Input className="w-100" />
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Dust Jacket</label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Library/Overstock</label>
                        <Input className="w-100" />
                      </div>{" "}
                      <div className="col-3 mb-4">
                        <label className="fw-bold">Media/Accessories</label>
                        <Input className="w-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmazonListingDetails;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TestingModules from "../../modules/pages/testing-modules/testing-modules";

import {
  SaveInventoryItemsWithoutOrder,
  fakeActionInventoryItems,
} from "../../redux/modules/inventory-items/index.action";

const mapStateToProps = (state) => ({
  SaveInventoryItemsWithoutOrderResponse:
    state.InventoryItems.SaveInventoryItemsWithoutOrderResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SaveInventoryItemsWithoutOrder,
      fakeActionInventoryItems,
    },
    dispatch
  );

const TestingModules_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestingModules);

export default TestingModules_;

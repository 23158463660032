export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_ASIN_INFO_SUCCESS":
    case "GET_ASIN_INFO_ERROR":
      return {
        ...state,
        GetAsinInfoActionResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ASIN_INFO":
      return [];
    default:
      return state;
  }
};

import { GetLinks } from "../../../../config";

export const AdminMenus = [
  {
    key: "/dashboard",
    icon: <i className="ki-outline ki-home-2 fs-1" />,
    id: "dashboard",
    label: "Dashboard",
    children: [
      {
        key: "/system-dashboard",
        id: "system_dashboard",
        icon: "",
        label: GetLinks("system-dashboard", "System Dashboard"),
      },
      {
        key: "/delivery-scanning",
        id: "delivery_scanning",
        icon: "",
        label: GetLinks("delivery-scanning", "Delivery Scanning"),
      },
      {
        key: "/receiving-home",
        id: "receiving_home",
        icon: "",
        label: "Receiving Home",
        children: [
          {
            key: "/receiving-items",
            id: "receiving_items",
            icon: "",
            label: GetLinks("/receiving-items", "Receiving Items"),
          },
          {
            key: "/receiving-picture-info",
            id: "receiving_picture_info",
            icon: "",
            label: GetLinks(
              "/receiving-picture-info",
              "Receiving Picture Info"
            ),
          },
        ],
      },
      {
        key: "/exceptions-home",
        id: "exceptions_home",
        icon: "",
        label: GetLinks("exceptions-home", "Exceptions Home"),
      },
      {
        key: "/shipping-home",
        id: "shipping_home",
        icon: "",
        label: "Shipping Home",
        children: [
          {
            key: "/shipments",
            id: "shipments",
            icon: "",
            label: GetLinks("/shipments", "Shipments"),
          },
        ],
      },
      {
        key: "/customer-support-home",
        id: "customer_support_home",
        icon: "",
        label: GetLinks("customer-support-home", "Customer Support Home"),
      },
      {
        key: "/sales-home",
        id: "sales_home",
        icon: "",
        label: GetLinks("sales-home", "Sales Home"),
      },
      {
        key: "/billing-home",
        id: "billing_home",
        icon: "",
        label: GetLinks("billing-home", "Billing Home"),
      },
      {
        key: "/hr-home",
        id: "hr_home",
        icon: "",
        label: GetLinks("hr-home", "HR Home"),
      },
    ],
  },
  {
    key: "/inventory-order",
    id: "inventory_order",
    icon: <i className="ki-outline ki-tablet-text-down fs-1" />,
    label: GetLinks("inventory-order", "Inventory Order"),
  },
  {
    key: "/inventory-items",
    id: "inventory_items",
    icon: <i className="ki-outline ki-parcel fs-1" />,
    label: GetLinks("inventory-items", "Inventory Items"),
  },
  {
    key: "/amazon-listings",
    id: "amazon_listings",
    icon: <i className="ki-outline ki-basket fs-1" />,
    label: GetLinks("amazon-listings", "Amazon Listings"),
  },
  {
    key: "/prep-batches",
    id: "prep_batches",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    label: GetLinks("prep-batches", "Prep Batches"),
  },
  {
    key: "/add-item-to-location",
    id: "add_item_to_location",
    icon: <i className="ki-outline ki-scan-barcode fs-1" />,
    label: GetLinks("add-item-to-location", "Add Item To Location"),
  },
  {
    key: "/sources",
    id: "sources",
    icon: <i className="ki-outline ki-shop fs-1" />,
    label: GetLinks("sources", "Sources"),
  },
  {
    key: "/entities",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    id: "entities",
    label: "Entities",
    children: [
      {
        key: "/client-account",
        id: "client_account",
        icon: "",
        label: GetLinks("client-account", "Client Account"),
      },
      {
        key: "/prep-staff",
        id: "prep_staff",
        icon: "",
        label: GetLinks("prep-staff", "Prep Staff"),
      },
      {
        key: "/prep-centers",
        id: "prep_centers",
        icon: "",
        label: "Prep Centers",
        children: [
          {
            key: "/locations",
            id: "locations",
            icon: "",
            label: GetLinks("locations", "Locations"),
          },
        ],
      },
    ],
  },
  // {
  //   key: "/scan-deliveries",
  //   id: "scan_deliveries",
  //   icon: <i className="ki-outline ki-scan-barcode fs-1" />,
  //   label: GetLinks("scan-deliveries", "Scan Deliveries"),
  // },
  // {
  //   key: "/receive-items",
  //   id: "receive_items",
  //   icon: <i className="bi bi-upc-scan fs-1" />,
  //   label: GetLinks("receive-items", "Receive Items"),
  // },

  {
    key: "/admin",
    icon: <i className="ki-outline ki-user-square fs-1" />,
    id: "admin",
    label: "Admin",
    children: [
      {
        key: "/cron-manager",
        id: "cron_manager",
        icon: "",
        label: GetLinks("cron-manager", "Cron Manager"),
      },
      {
        key: "/other-reports",
        id: "other_reports",
        icon: "",
        label: "Other Reports",
        children: [
          {
            key: "/pre-receiving-report",
            id: "pre_receiving_report",
            icon: "",
            label: GetLinks("pre-receiving-report", "Pre Receiving Report"),
          },
          {
            key: "/shell-listing-panel",
            id: "shell_listing_panel",
            icon: "",
            label: GetLinks("shell-listing-panel", "Shell Listing Panel"),
          },
          {
            key: "/item-pick-list",
            id: "item_pick_list",
            icon: "",
            label: GetLinks("item-pick-list", "Item Pick List"),
          },
          {
            key: "/asin-info",
            id: "asin_info",
            icon: "",
            label: GetLinks("item-pick-list", "ASIN Info"),
          },
        ],
      },
      {
        key: "/system-users",
        id: "system_users",
        icon: "",
        label: GetLinks("system-users", "System Users"),
      },
      {
        key: "/logs",
        id: "logs",
        icon: "",
        label: GetLinks("logs", "Logs"),
      },
    ],
  },
  {
    key: "/my-user",
    id: "my_user",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    label: GetLinks("my-user", "My User"),
  },
  {
    key: "/my-account",
    id: "my_account",
    icon: <i className="ki-outline ki-profile-circle fs-1" />,
    label: GetLinks("my-account", "My Account"),
  },
  {
    key: "/help",
    id: "help",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    label: GetLinks("help", "Help"),
  },
  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
];

export const UserMenus = [
  {
    key: "/dashboard",
    icon: <i className="ki-outline ki-home-2 fs-1" />,
    id: "dashboard",
    label: "Dashboard",
    children: [
      {
        key: "/system-dashboard",
        id: "system_dashboard",
        icon: "",
        label: GetLinks("system-dashboard", "System Dashboard"),
      },
    ],
  },
  {
    key: "/inventory-order",
    id: "inventory_order",
    icon: <i className="ki-outline ki-tablet-text-down fs-1" />,
    label: GetLinks("inventory-order", "Inventory Order"),
  },
  {
    key: "/sources",
    id: "sources",
    icon: <i className="ki-outline ki-shop fs-1" />,
    label: GetLinks("sources", "Sources"),
  },
  {
    key: "/inventory-items",
    id: "inventory_items",
    icon: <i className="ki-outline ki-parcel fs-1" />,
    label: GetLinks("inventory-items", "Inventory Items"),
  },
  {
    key: "/amazon-listings",
    id: "amazon_listings",
    icon: <i className="ki-outline ki-basket fs-1" />,
    label: GetLinks("amazon-listings", "Amazon Listings"),
  },
  {
    key: "/prep-batches",
    id: "prep_batches",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    label: GetLinks("prep-batches", "Prep Batches"),
  },

  {
    key: "/my-user",
    id: "my_user",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    label: GetLinks("my-user", "My User"),
  },
  {
    key: "/my-account",
    id: "my_account",
    icon: <i className="ki-outline ki-profile-circle fs-1" />,
    label: GetLinks("my-account", "My Account"),
  },
  {
    key: "/help",
    id: "help",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    label: GetLinks("help", "Help"),
  },
  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
];

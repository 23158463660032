import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { WarningMessage } from "../../../config";
import { message } from "antd";

const TestingModules = (props) => {
  const { SaveInventoryItemsWithoutOrder, fakeActionInventoryItems } = props;

  const SaveInventoryItemsWithoutOrderRes = useSelector(
    (state) => state.InventoryItems.SaveInventoryItemsWithoutOrderResponse || {}
  );

  const inputFile = useRef(null);

  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [facingMode, setFacingMode] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [cameraModal, setCameraModal] = useState(false);
  const [chooseCameraOptionsModal, setChooseCameraOptionModal] =
    useState(false);
  const [currentOpenFiled, setCurrentOpenFiled] = useState("receiving_front");

  const [front_preview, set_front_preview] = useState("");
  const [frontProductImg, setFrontProductImg] = useState("");
  const [receptionNotes, setReceptionNotes] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);

  // if (window.innerWidth > 992) {
  //     history.push('/')
  // }

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraDevices(cameras);
        // Select the default camera
        console.log("cameras", cameras);
        if (cameras.length > 0) {
          setSelectedCamera(cameras[0].deviceId);
        }
      })
      .catch((err) => console.error("Error fetching camera devices:", err));
  }, []);

  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          deviceId: { exact: selectedCamera },
          width: { ideal: 3024 },
          height: { ideal: 4032 },
          facingMode: "environment",
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      streamRef.current = stream;
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set the canvas size to match the video resolution
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Ensure high-quality rendering
    context.imageSmoothingEnabled = true;

    // Draw the video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas content to a base64 image (for preview)
    const imageData = canvas.toDataURL("image/jpeg"); // Lossless

    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], "captured-image.jpeg", {
          type: "image/jpeg",
        });
        console.log(file); // This is a high-quality image file

        if (currentOpenFiled === "receiving_front") {
          setFrontProductImg(file);
          set_front_preview(imageData);
        }
      }
    }, "image/jpeg"); // PNG ensures lossless quality

    // Stop the camera after capturing the image
    stopCamera();
    setCameraModal(false);
  };

  const handleOpenCamera = (section) => {
    setCurrentOpenFiled(section);
    if (cameraDevices.length === 1) {
      setCameraModal(true);
      startCamera();
    } else {
      setChooseCameraOptionModal(true);
    }
  };

  const handleOpenSelectedCamera = (camera) => {
    setFacingMode(
      camera?.label?.toLowerCase()?.includes("front") ? "user" : "environment"
    );
    startCamera();
  };

  useEffect(() => {
    if (SaveInventoryItemsWithoutOrderRes?.status === true) {
      message.destroy();
      message.success(SaveInventoryItemsWithoutOrderRes?.message);
      fakeActionInventoryItems();
    } else if (SaveInventoryItemsWithoutOrderRes?.status === false) {
      message.destroy();
      const newData = Object.entries(
        SaveInventoryItemsWithoutOrderRes?.data || {}
      )
        ?.map((d) => {
          return d[1];
        })
        ?.flat();
      const ErrorData =
        newData?.length > 0
          ? newData
          : [SaveInventoryItemsWithoutOrderRes?.message];
      WarningMessage(
        ErrorData?.map((d) => {
          return <p>{d}</p>;
        })
      );
      fakeActionInventoryItems();
    }
  }, [SaveInventoryItemsWithoutOrderRes]);

  const SavwInventoryData = () => {
    const data = {
      client_account_id: 13,
      inbound_tracking_id: 12345,
      barcode_received: "0840034431",
      disable_auto_error_detection: 0,
      receiving_problem: "",
      received_item_condition: "UsedGood",
      list_as_item_condition: "UsedGood",
      marks: "",
      dust_jacket: "",
      media: "",
      library_overstock: "",
      require_extended_repair: "",
      is_set_manual: "",
      no_of_items_in_set: "",
      type_of_set: "",
      is_boxed_set: "",
      notes: "",
      reception_notes: "",
      list_as_asin: "0840034431",
      listing_id: 1025,
      is_skip_image_upload: 0,
      image_front_cover: "",
      image_back_side: "",
      image_problem: "",
      image_another_problem: "",
      image_front_left_bottom: "",
      image_front_right_top: "",
    };

    const newData = { ...data, image_front_cover: frontProductImg };
    if (frontProductImg) {
      message.destroy();
      message.loading("Loading...", 0);
      const formData = new FormData();
      Object.entries(newData)?.map((d) => {
        formData.append(d?.[0], d?.[1]);
      });
      SaveInventoryItemsWithoutOrder(formData);
    } else {
      message.destroy();
      message.error("Please capture the product image first.");
    }
  };

  console.log(frontProductImg, "frontProductImg");

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row">
            <div className="col-12 my-2">
              <div className="card " style={{ height: "100%" }}>
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      Testing Module
                    </span>
                  </h3>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <h2>Camera Capture</h2>
                        {cameraDevices?.length > 0
                          ? cameraDevices?.map((camera) => {
                              return (
                                <div className="col-md-4" key={camera.deviceId}>
                                  <div className="camera-options">
                                    <input
                                      className="form-check-input d-none"
                                      type="radio"
                                      id={`camera_option_${camera.deviceId}`}
                                      name="camera_option"
                                      checked={
                                        selectedCamera === camera?.deviceId
                                      }
                                      onChange={() =>
                                        setSelectedCamera(camera.deviceId)
                                      }
                                    />
                                    <label
                                      className={`form-check-label fs-6 text-dark fw-bold cursor-pointer ${
                                        selectedCamera === camera?.deviceId
                                          ? "selected"
                                          : ""
                                      }`}
                                      htmlFor={`camera_option_${camera.deviceId}`}
                                    >
                                      <div className="options text-center">
                                        <span className="svg-icon svg-icon-muted svg-icon-7hx">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-camera-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                                          </svg>
                                        </span>
                                      </div>
                                      <p className="text-center">
                                        {`Camera ${
                                          cameraDevices.indexOf(camera) + 1
                                        } ${
                                          camera?.deviceId
                                        } - ${selectedCamera} - ${
                                          camera.label
                                            .toLowerCase()
                                            .includes("front")
                                            ? "user"
                                            : "environment"
                                        }`}
                                      </p>
                                    </label>
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleOpenSelectedCamera(camera);
                                    }}
                                  >
                                    Open
                                  </button>
                                </div>
                              );
                            })
                          : "No Camera Found"}
                      </div>{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <img
                        src={front_preview}
                        width={300}
                        height={200}
                        style={{ objectFit: "contain" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row g-3 gx-7 mb-0">
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      style={{ maxWidth: "100%" }}
                    />
                    <div className="pt-5 text-center">
                      <button
                        className="btn btn-primary fs-7 me-3"
                        onClick={() => captureImage()}
                      >
                        Capture Image
                      </button>
                    </div>
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          SavwInventoryData();
                        }}
                      >
                        Save Data
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingModules;
